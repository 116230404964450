import axios from "axios";

let axiosInstanceBasicLos = axios.create();

axiosInstanceBasicLos.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (localStorage.getItem("tkn") !== null) {
      const auth = localStorage.getItem("tkn");
      const authJson = atob(auth);
      config.headers.Authorization = `Bearer ${authJson}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const HOST_TTF_LENDER_SERVICE =
  "https://los.trustiq.id/services/ttflender";
export const HOST_LOS = "https://los.trustiq.id";
export const HOST_LOAN_SERVICE = "https://loan.trustiq.id/api/v1/partner/loan";
export const HOST_TTF_LOG_ACTIVITY =
  "https://log.trustiq.id/api/v1/transaction/logger";
// export const HOST_TTF_TRANSACTION_APP_USER = "http://log.trustiq.id/api/v1/transaction/app-user-login";
export const HOST_TTF_TRANSACTION_APP_USER =
  "https://los.trustiq.id/services/ttflender/api/other/user-login";
export const HOST_EKYC_KTP = "https://dev.trustiq.id:3001/ktp-photo";
export const HOST_EKYC_VIDEO = "https://dev.trustiq.id:3001/kyc-video";
export const HOST_OSS_GENERATOR =
  "https://los.trustiq.id/services/ttfdigisignperuri/api/oss/v1";
export const API_ASLIRI_URL = "https://los.trustiq.id/services/ttfasliri/api";
export const API_DIGIDATA_URL =
  "https://los.trustiq.id/services/ttfdigidata/api";
export const API_KYC_DATA =
  "https://los.trustiq.id/services/ttfkyc/api/kyc/v1/data";
export const API_DOC_DATA =
  "https://los.trustiq.id/services/ttfprivyid/api/oss/v1/contract";
export const OPEN_API_URL = "https://openapi.trustiq.id/v1/";
export const CASHLOAN_RPT = OPEN_API_URL + "cashloan/rpt/";

export const RPT_KYC_URL = CASHLOAN_RPT + "kyc/";
export const RPT_FINKU_URL = CASHLOAN_RPT + "finku/status/";

export const LOAN_DATA_MP = HOST_LOS + "/api/loan-data-mp/app/";

export const KYC_PHOTO_URL = "/oss/v1/kycFiles";

export const LOGIN_URL = "/api/user/authenticated/login";
export const REGISTER_URL = "api/auth/login";
export const REGISTER_CORPORATE_URL = "/api/lender/corporate/step-one";
export const REGISTER_PERSONAL_URL = "/api/lender/individual/step-one";
export const VERIFICATION_REGISTRATION = "/api/user/person-verification";
export const SEND_EMAIL_VERIFICATION_REGISTRATION =
  "/api/send-email/person-verification";
export const CHECKING_VERIFICATION = "/api/user/person-verification/";
export const GET_USER_CORPORATE_BY_LENDER_ID = "/api/lender/corporate/";
export const GET_USER_PERSONAL_BY_LENDER_ID = "/api/lender/individual/";
export const CREATE_PROFILE_CORPORATE = "/api/admin/lender/corporate/";
export const CREATE_REQUEST_WITHDRAWAL = "/api/withdrawal/";
export const CREATE_PROFILE_PERSONAL = "/api/admin/lender/individual/";
export const GET_INDEX_ADMIN = "/api/admin/lender/index";
export const GET_LENDER_BANK_BY_ID = "/api/lender-bank/drop-down/";
export const GET_WITHDRAW = "/api/withdrawal/grid-view-lender/";
export const GET_PROVINCE = "/api/dropdown/province";
export const GET_CITY = "/api/dropdown/city";
export const GET_DISTRICT = "/api/dropdown/district";
export const GET_VILLAGE = "/api/dropdown/village";
export const GET_ZIPCODE = "/api/dropdown/zipcode";
export const GET_ECONOMIC_SECTOR = "/api/dropdown/economic-sector";
export const GET_BUSINESS_FIELD = "/api/dropdown/business-field";
export const GET_CORPORATE_TYPE = "/api/dropdown/corporate-type";
export const GET_JOB = "/api/dropdown/job";
export const GET_JOB_ROLE = "/api/dropdown/job-role";
export const GET_LENDER_CORPORATE_PERSON = "/api/lender-corporate-person/";
export const GET_LENDER_BANK = "/api/lender-bank/";
export const GET_BANK = "/api/dropdown/bank";
export const GET_ASSET = "/api/dropdown/asset";
export const CREATE_LENDER_BANK = "/api/lender-bank/";
export const UPDATE_LENDER_BANK = "/api/lender-bank/";
export const DELETE_LENDER_BANK = "/api/lender-bank/";
export const GET_LENDER_PRODUCT_BY_LENDER_ID = "/api/lender-product/grid-view";
export const GET_LENDER_PRODUCT_DROPDOWN = "/api/lender-product/choose-product";
export const GET_LENDER_TRANSFER = "/api/lender-transfer/grid-view";
export const CREATE_LENDER_PRODUCT = "/api/lender-product/";
export const DELETE_LENDER_PRODUCT = "/api/lender-product/";
export const CREATE_LENDER_CORPORATE_PERSON = "/api/lender-corporate-person/";
export const UPDATE_LENDER_CORPORATE_PERSON = "/api/lender-corporate-person/";
export const CREATE_LENDER_TRANSFER = "/api/lender-transfer/";
export const CREATE_CRITERIA = "/api/criteria/";
export const SEND_LENDER_DOCUMENT = "/api/lender-document/";
export const GET_DATA_RDL = "/api/dashboard-lender/dashboard-one/";
export const GET_LOAN_SUMMARY = "/api/dashboard-lender/loan-summary/";
export const GET_LOAN_LIST = "/api/dashboard-lender/loan-list/";
export const GET_REPAYMENT_LIST = "/api/v1/partner/loan/repayment/list/";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const SEARCH_BORROWER = "/borrower/search/";
export const CHANGE_PASSWORD = "/api/user/change-password";
export const REPAYMENT_RECOVERY =
  HOST_LOAN_SERVICE + "/repayment-recovery/list/";
export const INFO_BUKU_WO = HOST_LOAN_SERVICE + "/write-off/list/";

export const ME_URL = "api/me";

// check user is authorized
function isUserAuthorized() {
  var uli = atob(localStorage.getItem("uli"));
  console.log(uli);
  if (uli) {
    return `${HOST_TTF_TRANSACTION_APP_USER}/${uli}`;
  }

  return `${HOST_TTF_TRANSACTION_APP_USER}/-`;
}

// do force logout
function forceLogout(error) {
  if (error.response) {
    if (error.response.status === 500) {
      localStorage.removeItem("persist:ttf-auth");
      localStorage.removeItem("uli");
      window.location.reload();
    }
  }
}

export function login(username, password, captcha) {
  // return axios.get(LOGIN_URL, { params:{ username, password,role:'lender'} });
  return axios.get(HOST_TTF_LENDER_SERVICE + LOGIN_URL, {
    params: { username, password, role: "admin", appSystemCode: "CS", captcha }
  });
}

export function loginAdmin(username, password) {
  return axios.get(LOGIN_URL, {
    params: { username, password, role: "admin" }
  });
}

export function getUserCorporateByLenderId(lenderId) {
  return axios.get(GET_USER_CORPORATE_BY_LENDER_ID + lenderId);
}

export function getUserPersonalByLenderId(lenderId) {
  return axios.get(GET_USER_PERSONAL_BY_LENDER_ID + lenderId);
}

export function registerIndividual(
  appUsername,
  email,
  appPassword,
  personName,
  personVerificationId
) {
  return axios.post(REGISTER_PERSONAL_URL, {
    appUsername,
    appPassword,
    email,
    personName,
    createdFrom: "WEB",
    registrationStep: "NEW",
    personVerificationId
  });
}

export function registerCorporate(
  appUsername,
  email,
  appPassword,
  corporateType,
  corporateName,
  personVerificationId
) {
  return axios.post(REGISTER_CORPORATE_URL, {
    appUsername,
    appPassword,
    corporateType,
    email,
    corporateName,
    createdFrom: "WEB",
    registrationStep: "NEW",
    personVerificationId
  });
}

export function sendEmailVerification(emailTo, link) {
  return axios({
    url: SEND_EMAIL_VERIFICATION_REGISTRATION,
    method: "POST",
    params: { emailTo, link }
  });
}

export function verificationRegistration(names, email, token, personType) {
  return axios.post(VERIFICATION_REGISTRATION, {
    names,
    email,
    token,
    personType
  });
}

export function checkingVerification(token) {
  return axios.get(CHECKING_VERIFICATION + token);
}

export function createLenderCorporate(data) {
  return axios.post(CREATE_PROFILE_CORPORATE, data);
}

export function createLenderPersonal(data) {
  return axios.post(CREATE_PROFILE_PERSONAL, data);
}

export function createRequestWithdraw(data) {
  return axios.post(CREATE_REQUEST_WITHDRAWAL, data);
}

export function getLenderBankById(lenderId) {
  return axios.get(GET_LENDER_BANK_BY_ID + lenderId);
}

export function getWithdraw(lenderId) {
  return axios.get(GET_WITHDRAW + lenderId);
}

export function getProvince() {
  return axios.get(GET_PROVINCE);
}

export function getCity(provinceId) {
  return axios.get(GET_CITY, { params: { provinceId } });
}

export function getDistrict(cityId) {
  return axios.get(GET_DISTRICT, { params: { cityId } });
}

export function getVillage(districtId) {
  return axios.get(GET_VILLAGE, { params: { districtId } });
}

export function getZipCode(villageId) {
  return axios.get(GET_ZIPCODE, { params: { villageId } });
}

export function getEconomicSector() {
  return axios.get(GET_ECONOMIC_SECTOR);
}

export function getBusinessField() {
  return axios.get(GET_BUSINESS_FIELD);
}

export function getJob() {
  return axios.get(GET_JOB);
}

export function getJobRole() {
  return axios.get(GET_JOB_ROLE);
}

export function getLenderCorporatePerson(lenderId) {
  return axios.get(GET_LENDER_CORPORATE_PERSON + lenderId);
}

export function getIndexAdmin() {
  return axios.get(GET_INDEX_ADMIN);
}

export function getBank() {
  return axios.get(GET_BANK);
}

export function getAsset() {
  return axios.get(GET_ASSET);
}

export function getCorporateType() {
  return axios.get(GET_CORPORATE_TYPE);
}

export function getLenderBankByLenderId(lenderId) {
  return axios.get(GET_LENDER_BANK + `grid-view?lenderId=${lenderId}`);
}

export function getLenderBankByLenderBankId(lenderBankId) {
  return axios.get(GET_LENDER_BANK + lenderBankId);
}

export function createLenderBank(data) {
  return axios.post(CREATE_LENDER_BANK, data);
}

export function updateLenderBank(lenderBankId, data) {
  return axios({
    url: UPDATE_LENDER_BANK,
    method: "PUT",
    params: { lenderBankId },
    data
  });
}

export function deleteLenderBank(lenderBankId) {
  return axios.delete(DELETE_LENDER_BANK + lenderBankId);
}

export function getLenderProductByLenderId(lenderId) {
  return axios.get(GET_LENDER_PRODUCT_BY_LENDER_ID + `?lenderId=${lenderId}`);
}

export function getLenderProductDropdown(lenderId) {
  return axios.get(GET_LENDER_PRODUCT_DROPDOWN + `?lenderId=${lenderId}`);
}

export function createLenderProduct(data) {
  return axios.post(CREATE_LENDER_PRODUCT, data);
}

export function deleteLenderProduct(lenderProductId) {
  return axios.delete(DELETE_LENDER_PRODUCT + lenderProductId);
}

export function createLenderCorporatePerson(data) {
  return axios.post(CREATE_LENDER_CORPORATE_PERSON, data);
}

export function updateLenderCorporatePerson(lenderId, data) {
  return axios.post(UPDATE_LENDER_CORPORATE_PERSON + lenderId, data);
}

export function getLenderTransfer(LenderBankId) {
  return axios.get(GET_LENDER_TRANSFER + `?lenderId=${LenderBankId}`);
}

export function createLenderTransfer(data) {
  return axios.post(CREATE_LENDER_TRANSFER, data);
}

export function createCriteria(data) {
  return axios.post(CREATE_CRITERIA, data);
}

export function SendDocument(lenderId, type, userBy, image) {
  return axios.post(SEND_LENDER_DOCUMENT, { lenderId, type, userBy, image });
}

export function getDataRDL(lenderId) {
  return axios.get(GET_DATA_RDL + lenderId);
}

export function getLoanSummary(lenderId) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.get(GET_LOAN_SUMMARY + lenderId))
    .catch(error => forceLogout(error));
}

export function getLoanList(lenderId) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.get(GET_LOAN_LIST + lenderId))
    .catch(error => forceLogout(error));
}

export function getRepaymentList(loanId) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.get(HOST_LOAN_SERVICE + "/repayment/list/" + loanId))
    .catch(error => forceLogout(error));
}

export function requestPassword(email) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.post(REQUEST_PASSWORD_URL, { email }))
    .catch(error => forceLogout(error));
}

export function getUserByToken() {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.get(ME_URL))
    .catch(error => forceLogout(error));
}

export function searchBorrower(key) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.get(SEARCH_BORROWER + key))
    .catch(error => forceLogout(error));
}

export function changeNewPassword(data) {
  return axiosInstanceBasicLos
    .get(isUserAuthorized())
    .then(() => axios.post(HOST_TTF_LENDER_SERVICE + CHANGE_PASSWORD, data))
    .catch(error => forceLogout(error));
}

export function logActivity(sTID, eTID, userID, username, fullname, remarks) {
  return axios.post(HOST_TTF_LOG_ACTIVITY, {
    systemTypeId: sTID,
    logTypeId: 1,
    eventTypeId: eTID,
    userId: userID,
    username: username,
    fullName: fullname,
    remarks: remarks
  });
}

export function getKYCData(appReqId) {
  return axiosInstanceBasicLos.get(
    API_KYC_DATA +
      "?applicationRequestId=" +
      appReqId +
      "&expiration=" +
      86400000
  );
}

export function getDocsData(appReqId) {
  return axiosInstanceBasicLos.get(
    API_DOC_DATA +
      "?applicationRequestId=" +
      appReqId +
      "&expiration=" +
      86400000
  );
}

export function appUserLogin(sTID, appUserId) {
  return axiosInstanceBasicLos.post(HOST_TTF_TRANSACTION_APP_USER, {
    appUserId: appUserId,
    systemTypeId: sTID
  });
}

export function generateDownloadURL(bucketName, objKey, expiration) {
  return axios.post(HOST_OSS_GENERATOR + "/generateSignedUrl", {
    bucketName: bucketName,
    objKey: objKey,
    expiration: expiration
  });
}

export async function getAsliriKycPhoto({ applicationRequestId, expiration }) {
  try {
    expiration = 86400000;
    const res = await axios.post(API_ASLIRI_URL + KYC_PHOTO_URL, {
      applicationRequestId,
      expiration
    });
    return [res, null];
  } catch (e) {
    return [null, e];
  }
}

export async function getDigidataKycPhoto({
  applicationRequestId,
  expiration = null
}) {
  try {
    expiration = 86400000;
    const res = await axios.post(API_DIGIDATA_URL + KYC_PHOTO_URL, {
      applicationRequestId,
      expiration
    });
    return [res, null];
  } catch (e) {
    return [null, e];
  }
}

export async function getKycPhoto({ applicationRequestId, expiration = null }) {
  const [asliriPhotoRes, asliriPhotoError] = await getAsliriKycPhoto({
    applicationRequestId,
    expiration
  });
  if (
    asliriPhotoRes &&
    asliriPhotoRes.data.livenessCheckData &&
    asliriPhotoRes.data.professionalVerificationData &&
    !asliriPhotoError
  ) {
    return [asliriPhotoRes, asliriPhotoError];
  }

  const [digidataPhotoRes, digidataPhotoError] = await getDigidataKycPhoto({
    applicationRequestId,
    expiration
  });
  return [digidataPhotoRes, digidataPhotoError];
}

export const getRPTKYC = async appReqId =>
  axiosInstanceBasicLos.get(RPT_KYC_URL + appReqId);

export const getRPTFinku = async appReqId =>
  axiosInstanceBasicLos.get(RPT_FINKU_URL + appReqId);

export async function getLoanDataMP(appId) {
  return await axios.get(LOAN_DATA_MP + appId);
}

export const getRepaymentRecovery = async loanId =>
  await axios.get(REPAYMENT_RECOVERY + loanId);

export const getInfoBukuWO = async loanId =>
  await axios.get(INFO_BUKU_WO + loanId);
