import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { logActivity } from "../../crud/auth.crud";

class Logout extends Component {
  componentDidMount() {
    var sess = atob(localStorage.getItem("ud"));
    var decode = JSON.parse(sess);
    logActivity(
      5,
      2,
      decode.appUserId,
      decode.userName,
      decode.fullname,
      "User: " + decode.userName + " logged out CS App"
    ).then(({ data: { responseCode } }) => {
      if (responseCode !== "000") {
        // nothing to do for now
      }
    });

    localStorage.removeItem("uli");
    localStorage.removeItem("ud");
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
