import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CSBorrower from "./CSBorrower";
import ChangePassword from './ChangePassword'
import { LayoutSplashScreen } from "../../../_metronic";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentWillMount() {
    if (!this.state.user) {
      const auth = localStorage.getItem("persist:ttf-auth");
      const authJson = JSON.parse(auth);
      if (authJson.user) {
        const data = JSON.parse(authJson.user);
        if (data) {
          this.state.user = data;
        }
      }
    }
  }

  render() {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {<Redirect exact from="/" to="/borrower" />}
          <Route path="/borrower" component={CSBorrower} />
          <Route path="/change-password" component={ChangePassword}/>
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}
