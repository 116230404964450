import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Grid from '@material-ui/core/Grid';

class Footer extends React.Component {
  render() {
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <section id="contact" className="contactus">
              <div className="container">
                <footer>
                  <p>©2020 by trustiq.id<br/>
                  Office 8, Lantai 35, Unit H, Jl. Senopati Raya No. 08B, Jakarta 12190 - Indonesia</p>
                </footer>
                <div className="clearfix"></div>
              </div>
            </section>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
