import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Header from "../../../_metronic/layout/headerAuth/Topbar"
import Footer from "../../../_metronic/layout/footer/Footer"
import ForgotPassword from "./ForgotPassword";

export default function AuthPage() {
  return (
      <>
        <Header style/>
        <div style={{marginTop : 10}}></div>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
              id="kt_login"
              className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                  className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/works.png")})`
                  }}
              >
                <div className="kt-grid__item">
                  <Link to="/" className="kt-login__logo">
                    <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/logos/icon-app.png")}
                    />
                  </Link>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                  <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title">Cara Kerja.</h3>
                    <h4 className="kt-login__subtitle">
                      Anda hanya perlu melakukan 5 langkah mudah.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </>
  );
}
