import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login, logActivity, appUserLogin } from "../../crud/auth.crud";
import { sha256 } from "../../../_metronic/utils/utils";
import ReCAPTCHA from "react-google-recaptcha";

function Login(props) {
  const { intl } = props;
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
              captcha: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD",
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              enableLoading();
              var password = await sha256(values.password);
              setTimeout(() => {
                login(values.email, password, values.captcha)
                  .then(({ data: { responseCode, responseMessage, data: LoginData } }) => {
                    localStorage.setItem("apc", btoa(LoginData.appRoleCode));
                    disableLoading();

                    if (responseCode === "000") {
                      localStorage.setItem("tkn", btoa(responseMessage));

                      props.login(LoginData);

                      appUserLogin(5, LoginData.userName)
                        .then(({ data: { responseCode, data } }) => {
                          if (responseCode === "000") {
                            logActivity(5, 1, LoginData.appUserId, LoginData.userName, LoginData.fullname, "User: " + LoginData.userName + " logged into CS App").then(({ data: { responseCode } }) => {
                              if (responseCode !== "000") {
                                // nothing to do for now
                              }
                            });

                            localStorage.setItem("ud", btoa(JSON.stringify(LoginData)));
                            localStorage.setItem("uli", btoa(data.appUserLoginId));
                          } else {
                            disableLoading();
                            setSubmitting(false);
                            setStatus(
                              intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN",
                              })
                            );
                          }
                        })
                        .catch((error) => {
                          disableLoading();
                          setSubmitting(false);
                          setStatus(
                            intl.formatMessage({
                              id: "AUTH.VALIDATION.INVALID_LOGIN",
                            })
                          );
                        });
                    } else {
                      recaptchaRef.current.reset();
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN",
                        })
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    recaptchaRef.current.reset();
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN",
                      })
                    );
                  });
              }, 1000);
            }}
          >
            {({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : null}

                <div className="form-group">
                  <TextField type="email" label="Email" margin="normal" className="kt-width-full" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />
                </div>

                <div className="form-group">
                  <TextField type="password" margin="normal" label="Password" className="kt-width-full" name="password" onBlur={handleBlur} onChange={handleChange} value={values.password} helperText={touched.password && errors.password} error={Boolean(touched.password && errors.password)} />
                </div>

                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LccEz0aAAAAAK02ukPwkjRUNH1wj4bzjRsN9grW"
                    onChange={(value) => {
                      setFieldValue("captcha", value);
                    }}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                    })}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
