// jshint ignore:start

import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getInfoBukuWO } from "../../../crud/auth.crud";
import { formatDate, toIndonesiaCurrency } from "../../../../_metronic";
import { Typography } from "@material-ui/core";

export const InfoHapusBukuView = ({ loanId }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (loanId)
      getInfoBukuWO(loanId).then(res => {
        setData(res.data.loanWriteOf);
      });
  }, [loanId]);

  if (!Object.keys(data).length) return null;

  return (
    <>
      <Typography style={{ marginBottom: "1rem", fontWeight: "bold" }}>
        INFORMASI HAPUS BUKU/WO
      </Typography>
      <Table hover>
        <tbody>
          <tr>
            <td>Tgl Hapus Buku</td>
            <td>{formatDate(data.tanggalHapusBuku)}</td>
          </tr>
          <tr>
            <td>Total Hapus Buku</td>
            <td>{toIndonesiaCurrency(data.totalHapusBuku)}</td>
          </tr>
          <tr>
            <td>Pokok di Hapus Buku</td>
            <td>{toIndonesiaCurrency(data.pokokHapusBuku)}</td>
          </tr>
          <tr>
            <td>Bunga di Hapus Buku</td>
            <td>{toIndonesiaCurrency(data.bungaHapusBuku)}</td>
          </tr>
          <tr>
            <td>Tgl jatuh Tempo Terakhir</td>
            <td>{formatDate(data.tanggalJatuhTempoTerakhir)}</td>
          </tr>
          <tr>
            <td>DPD Terakhir</td>
            <td>{data.dpdTerakhir}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
