// jshint ignore:start

import React, { useEffect, useState } from "react";
import { getRepaymentRecovery } from "../../../crud/auth.crud";
import { Table } from "react-bootstrap";
import { formatDate, toIndonesiaCurrency } from "../../../../_metronic";
import { Typography } from "@material-ui/core";

export const RecoveryPaymentTable = ({ loanId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (loanId)
      getRepaymentRecovery(loanId).then(res => {
        setData(res.data.loan);
      });
  }, [loanId]);

  if (!data.length) return null;

  return (
    <>
      <Typography style={{ marginBottom: "1rem", fontWeight: "bold" }}>
        RECOVERY PAYMENT
      </Typography>
      <Table responsive="sm" hover>
        <thead>
          <tr>
            <th>Tgl Pembayaran</th>
            <th>Nilai Pembayaran</th>
            <th>Channel</th>
          </tr>
        </thead>
        <tbody>
          {data.map(function(item, i) {
            return (
              <tr key={i}>
                <td>{formatDate(item.recoveryRepaymentDate)}</td>
                <td>{toIndonesiaCurrency(item.recoveryRepaymentAmount)}</td>
                <td>{item.channel}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
