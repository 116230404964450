import { HOST_EKYC_KTP } from '../crud/auth.crud'

export const getPhotoUrl = (url) => {
  if (url.includes('https://') || url.includes('http://')) {
    return url
  }
  return HOST_EKYC_KTP +
    '/' +
    url
}
