import React from "react";
import { toAbsoluteUrl } from "../../utils/utils";
import { Navbar} from 'react-bootstrap'


export default class Topbar extends React.Component {
  render() {
      const headerLogo = toAbsoluteUrl(`/media/logos/rsz_1ttf-logo.png`)

      const divStyle = {
          background: 'fff'
      };
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/" style={divStyle}><img alt="logo" src={headerLogo}/></Navbar.Brand>

        </Navbar>
    );
  }
}
