import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import CodeExample from "../../partials/content/CodeExample";
import { changeNewPassword, logActivity } from "../../crud/auth.crud";
import { sha256 } from "../../../_metronic";
import zxcvbn from "zxcvbn";
import "../../../_metronic/_assets/css/progress-password.css";

export default function ChangePassword(props) {
  const { intl } = props;
  const auth = localStorage.getItem("persist:ttf-auth");
  const authJson = JSON.parse(auth);
  var user = null;
  if (authJson.user) {
    const data = JSON.parse(authJson.user);
    if (data) {
      user = data;
    }
  }

  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  return (
    <div className="container">
      <CodeExample beforeCodeTitle="Ganti Password">
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                newRepassword: "",
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                if (
                  values.newPassword === values.newRepassword &&
                  zxcvbn(values.newPassword).score >= 3
                ) {
                  var data = {
                    appUserId: user.appUserId,
                    passwordNew: await sha256(values.newPassword),
                    passwordOld: await sha256(values.oldPassword),
                  };
                  changeNewPassword(data)
                    .then(({ data: { responseCode } }) => {
                      var sess = atob(localStorage.getItem("ud"));
                      var decode = JSON.parse(sess);
                      logActivity(
                        5,
                        3,
                        decode.appUserId,
                        decode.userName,
                        decode.fullname,
                        "User: " + decode.userName + " has changed password"
                      ).then(({ data: { responseCode } }) => {
                        if (responseCode !== "000") {
                          // nothing to do for now
                        }
                      });

                      setSubmitting(false);
                      if (responseCode === "000") {
                        // alert.success("Ganti Password Berhasil!");
                      } else {
                        // alert.error("Ganti Password Gagal!");
                      }
                    })
                    .catch(() => {
                      setSubmitting(false);
                      // alert.error(
                      //   "Ganti Password Gagal!, Harap Hubungi Administrator"
                      // );
                    });
                } else {
                  // alert.error("Harap Masukkan Password Yang Kuat");
                }
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="password"
                      label="Kata Sandi Lama"
                      margin="normal"
                      fullWidth={true}
                      name="oldPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.oldPassword}
                      helperText={touched.oldPassword && errors.oldPassword}
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                    />

                    <TextField
                      type="password"
                      label="Kata Sandi Baru"
                      margin="normal"
                      fullWidth={true}
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                    />
                    <div className="password-strength-meter">
                      <progress
                        className={`password-strength-meter-progress strength-${createPasswordLabel(
                          zxcvbn(values.newPassword)
                        )}`}
                        value={zxcvbn(values.newPassword).score}
                        max="4"
                      />
                      <br />
                      <label className="password-strength-meter-label">
                        {values.newPassword && (
                          <>
                            <strong>Password strength:</strong>{" "}
                            {createPasswordLabel(zxcvbn(values.newPassword))}
                          </>
                        )}
                      </label>
                    </div>

                    <TextField
                      type="password"
                      label="Ulang Kata Sandi Baru "
                      margin="normal"
                      fullWidth={true}
                      name="newRepassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.newRepassword}
                      helperText={touched.newRepassword && errors.newRepassword}
                      error={Boolean(
                        touched.newRepassword && errors.newRepassword
                      )}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary`}
                      name="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </CodeExample>
    </div>
  );
}
