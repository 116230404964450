/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CodeExample from "../../partials/content/CodeExample";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  Typography,
  Grid,
  IconButton,
  Box
} from "@material-ui/core";
import { Button, Form, Col, Table, Spinner, Image } from "react-bootstrap";
import {
  formatDate,
  formatDateWithTime,
  toIndonesiaCurrency
} from "../../../_metronic/utils/utils";
import {
  searchBorrower,
  getRepaymentList,
  generateDownloadURL,
  logActivity,
  getKYCData,
  getDocsData,
  getRPTKYC,
  getRPTFinku,
  getLoanDataMP
} from "../../crud/auth.crud";
import { Player } from "video-react";
import Viewer from "react-viewer";
import { getPhotoUrl } from "../../utils/getPhotoUrl";
import { getLivenessPhotoList } from "../../utils/getLivenessPhotoList";
import { FileCopy } from "@material-ui/icons";
import { InfoHapusBukuView } from "../../partials/ui/CSBorrower/InfoHapusBukuView";
import { RecoveryPaymentTable } from "../../partials/ui/CSBorrower/RecoveryPaymentTable";

export const OSS_URL_BORROWER = "https://dev.trustiq.id:3002/borrower-signed/";
export const OSS_URL_LENDER = "https://dev.trustiq.id:3002/lender-signed/";
export const BUCKET = "live-digisign";
export const OBJ_KEY_LENDER = "lender-signed";
export const OBJ_KEY_BORROWER = "borrower-signed";

function TabContainer1(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer1.propTypes = {
  children: PropTypes.node.isRequired
};

// Example 9
const AntTabs9 = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "#1890ff"
  }
})(Tabs);

const AntTab9 = withStyles(theme => ({
  root: {
    minWidth: 72,
    marginRight: theme.spacing(4)
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles9 = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  typography: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#2e1534"
  }
}));

const GroupValueMapping = ({ label, value }) => (
  <>
    <Grid xs={6} item>
      {label}
    </Grid>
    <Grid xs={6} item>
      {value || "-"}
    </Grid>
  </>
);

export default function CSBorrower() {
  const classes9 = useStyles9();

  const [value9, setValue9] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [selfiePhotoVisible, setSelfiePhotoVisible] = React.useState(false);
  const [livenessPhotoVisible, setLivenessPhotoVisible] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchAlert, setSearchAlert] = React.useState(
    "Silahkan melakukan pencarian pada halaman pencarian di atas"
  );
  const [search, setSearch] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [videoUrl, setvideoUrl] = React.useState(null);
  const [ktpUrl, setKtpUrl] = React.useState("");
  const [result, setResult] = React.useState("");
  const [detail, setDetail] = React.useState(null);
  const [contractRes, setContractRes] = React.useState(null);
  const [detailName, setDetailName] = React.useState("");
  const [detailPayment, setDetailPayment] = React.useState([]);

  const [informasiPribadiHide, setInformasiPribadiHide] = React.useState(
    "block"
  );
  const [informasiPekerjaanHide, setInformasiPekerjaanHide] = React.useState(
    "block"
  );
  const [kontakDaruratHide, setKontakDaruratHide] = React.useState("block");
  const [informasiBankHide, setInformasiBankHide] = React.useState("block");

  const [selfiePhoto, setSelfiePhoto] = useState("");
  const [livenessPhoto, setLivenessPhoto] = useState([]);

  const [rptKYC, setRPTKYC] = useState({});
  const [rptFinku, setRPTFinku] = useState({});

  const apc = localStorage.getItem("apc");
  const decodeApc = atob(apc);

  const paymentLink =
    "https://web-repayment.trustiq.id/finku/active-loan/" +
    (detail && detail.itemDetail && detail.itemDetail.msisdn
      ? detail.itemDetail.msisdn
      : "");

  function handleChange9(event, newValue) {
    setValue9(newValue);
  }

  function handleSubmit(event) {
    setValidated(true);
  }

  useEffect(() => {
    // Fetch kyc photo data
    if (detail) {
      // getKycPhoto(
      //   { applicationRequestId: detail.itemDetail.application_request_id }).
      //   then(data => {
      //     const [res] = data
      //     if (res) {
      //       setSelfiePhoto(res.data.professionalVerificationData &&
      //         res.data.professionalVerificationData.selfiePhotoUrl)
      //       setLivenessPhoto(getLivenessPhotoList(res.data.livenessCheckData &&
      //         res.data.livenessCheckData.livenessPhotoFile))
      //     }
      //   })
      setLivenessPhoto(null);
      setSelfiePhoto(null);
      setResult(null);
      setvideoUrl(null);
      setKtpUrl(null);

      //get new kyc
      getKYCData(detail.itemDetail.application_request_id).then(resp => {
        if (
          resp.data.asliRiKycData !== null &&
          resp.data.asliRiKycData.livenessCheckData !== null
        ) {
          setLivenessPhoto(
            getLivenessPhotoList(
              resp.data.asliRiKycData.livenessCheckData.livenessPhotoFile
            )
          );
        } else if (
          resp.data.digidataKycData !== null &&
          resp.data.digidataKycData.livenessCheckData !== null
        ) {
          setLivenessPhoto(
            getLivenessPhotoList(
              resp.data.digidataKycData.livenessCheckData.livenessPhotoFile
            )
          );
        } else if (
          resp.data.privyKycData !== null &&
          resp.data.privyKycData.livenessCheckData !== null
        ) {
          setLivenessPhoto(
            getLivenessPhotoList(
              resp.data.privyKycData.livenessCheckData.livenessPhotoFile
            )
          );
        } else {
          setLivenessPhoto(null);
        }

        if (
          resp.data.asliRiKycData !== null &&
          resp.data.asliRiKycData.professionalVerificationData !== null
        ) {
          console.log("HERE ASLIRI");
          setSelfiePhoto(
            resp.data.asliRiKycData.professionalVerificationData.selfiePhotoUrl
          );
          setKtpUrl(
            resp.data.asliRiKycData.professionalVerificationData.govIdFileUrl
          );
          setResult(
            resp.data.asliRiKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
          setvideoUrl(null);
          console.log(
            "DATA RESULT : ",
            resp.data.asliRiKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
        } else if (
          resp.data.digidataKycData !== null &&
          resp.data.digidataKycData.professionalVerificationData !== null
        ) {
          console.log("HERE DIGIDATA");
          setSelfiePhoto(
            resp.data.digidataKycData.professionalVerificationData
              .selfiePhotoUrl
          );
          setResult(
            resp.data.digidataKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
          setKtpUrl(
            resp.data.digidataKycData.professionalVerificationData.govIdFileUrl
          );
          setvideoUrl(null);
          console.log(
            "DATA RESULT : ",
            resp.data.digidataKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
        } else if (
          resp.data.privyKycData !== null &&
          resp.data.privyKycData.professionalVerificationData !== null
        ) {
          console.log("HERE PRIVY");
          setSelfiePhoto(
            resp.data.privyKycData &&
              resp.data.privyKycData.professionalVerificationData.selfiePhotoUrl
          );
          setResult(
            resp.data.privyKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
          setKtpUrl(
            resp.data.privyKycData.professionalVerificationData.govIdFileUrl
          );
          setvideoUrl(null);
          console.log(
            "DATA RESULT : ",
            resp.data.privyKycData.professionalVerificationData.data.detail
              .selfiePhotoScore
          );
        } else if (
          resp.data.peruriKycData !== null &&
          resp.data.peruriKycData !== null
        ) {
          console.log("HERE Peruri");
          setSelfiePhoto(null);
          setLivenessPhoto(null);
          setvideoUrl(resp.data.peruriKycData.kycVideoUrl);
          if (resp.data.peruriKycData.finishKyc) {
            setResult("true");
          }
          setKtpUrl(resp.data.peruriKycData.govIdFileUrl);
          console.log("DATA RESULT : ", resp.data.peruriKycData.finishKyc);
        } else {
          setSelfiePhoto(null);
          setvideoUrl(null);
        }
      });

      // get docs
      getDocsData(detail.itemDetail.application_request_id).then(resp => {
        if (resp.status == 200) {
          setContractRes(resp.data);
        }
      });

      // Get RPT Data
      const appReqId = detail.itemDetail.application_request_id;
      getRPTKYC(appReqId).then(res => {
        if (res.data[0]) setRPTKYC(res.data[0]);
      });

      getRPTFinku(appReqId).then(res => {
        if (res.data[0]) setRPTFinku(res.data[0]);
      });
    }
  }, [detail]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <CodeExample beforeCodeTitle="Pencarian">
            <Form
              noValidate
              validated={validated}
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
                if (search.length >= 3) {
                  setDetail(null);
                  setDetailName("");
                  setLoader(true);

                  var sess = atob(localStorage.getItem("ud"));
                  var decode = JSON.parse(sess);
                  logActivity(
                    5,
                    6,
                    decode.appUserId,
                    decode.userName,
                    decode.fullname,
                    "User: " +
                      decode.userName +
                      " inquiry a search on Customer by keyword: " +
                      search
                  ).then(({ data: { responseCode } }) => {
                    if (responseCode !== "000") {
                      // nothing to do for now
                    }
                  });

                  searchBorrower(search)
                    .then(({ data: { code, data } }) => {
                      if (code === 200) {
                        setData(data);
                      } else {
                        setData([]);
                        setSearchAlert(
                          "Data tidak ditemukan, silahkan melakukan pencarian lagi pada halaman pencarian"
                        );
                      }

                      setLoader(false);
                    })
                    .catch(err => {
                      setData([]);
                      setSearchAlert(
                        "Data tidak ditemukan, silahkan melakukan pencarian lagi pada halaman pencarian"
                      );
                    });
                }
              }}
            >
              <Form.Row>
                <Form.Group as={Col} md="12">
                  {/* <Form.Label>First name</Form.Label> */}
                  <Form.Control
                    required
                    minLength="3"
                    type="text"
                    placeholder="Nama / NIK / No Hp"
                    onChange={e => {
                      setSearch(e.target.value);
                    }}
                  />
                  {/* <Form.Control.Feedback></Form.Control.Feedback> */}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <ul>
                  <li>Keyword wajib diisi</li>
                  <li>Keyword KTP minimal 7 huruf</li>
                  <li>Keyword selain KTP minimal 3 huruf</li>
                </ul>
              </Form.Row>
              <Button type="submit" style={{ float: "right" }}>
                Cari
              </Button>
            </Form>
          </CodeExample>
        </div>
      </div>

      {detail === null ? (
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle={"List Borrower"}>
              {data.length === 0 ? (
                <footer>
                  {loader ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    searchAlert
                  )}
                </footer>
              ) : (
                <div className="kt-section">
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>NIK</th>
                        <th>No.Hp</th>
                        <th>Produk</th>
                        <th>Tgl Pengajuan</th>
                        <th>Nominal</th>
                        <th>Status Aplikasi</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(function(item, i) {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.full_name}</td>
                            <td>{item.no_ktp}</td>
                            <td>{item.msisdn}</td>
                            <td>{item.product}</td>
                            <td>{formatDate(item.application_date)}</td>
                            <td>{toIndonesiaCurrency(item.loan_amount)}</td>
                            <td>{item.application_status}</td>
                            <td>
                              <Link
                                className="text-decoration-none"
                                onClick={e => {
                                  if (decodeApc === "CUST") {
                                    setValue9(4);
                                    setInformasiPribadiHide("none");
                                    setInformasiBankHide("none");
                                    setInformasiPekerjaanHide("none");
                                    setKontakDaruratHide("none");
                                  } else {
                                    setValue9(0);
                                    setInformasiPribadiHide("block");
                                    setInformasiBankHide("block");
                                    setInformasiPekerjaanHide("block");
                                    setKontakDaruratHide("block");
                                  }
                                  setData([]);
                                  setDetailName(item.full_name);
                                  setDetail({ itemDetail: item });
                                  setDetailPayment([]);

                                  getRepaymentList(item.fineract_loan_id).then(
                                    response => {
                                      if (response !== undefined) {
                                        if (response.data.code === 200) {
                                          setDetailPayment(
                                            response.data.loan.repaymentList
                                          );
                                        }
                                      }
                                    }
                                  );
                                }}
                              >
                                LIHAT
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </CodeExample>
          </div>
        </div>
      ) : (
        ""
      )}

      {detail !== null ? (
        <div className="row">
          <div className="col-md-12">
            <CodeExample beforeCodeTitle={detailName + " - Borrower Detail"}>
              <div className={classes9.root}>
                <div className={classes9.demo1}>
                  <AntTabs9 value={value9} onChange={handleChange9}>
                    <AntTab9
                      style={{ display: informasiPribadiHide }}
                      label="Informasi Pribadi"
                    />
                    <AntTab9
                      style={{ display: informasiPekerjaanHide }}
                      label="Informasi Pekerjaan"
                    />
                    <AntTab9
                      style={{ display: kontakDaruratHide }}
                      label="Kontak Darurat"
                    />
                    <AntTab9
                      style={{ display: informasiBankHide }}
                      label="Informasi Bank"
                    />
                    <AntTab9 label="Fasilitas Pinjaman" />
                  </AntTabs9>
                  {value9 === 0 && (
                    <TabContainer1>
                      <Grid container spacing={10}>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Nama</td>
                                <td>{detail.itemDetail.full_name}</td>
                              </tr>
                              <tr>
                                <td>NIK</td>
                                <td>{detail.itemDetail.no_ktp}</td>
                              </tr>
                              <tr>
                                <td>NPWP</td>
                                <td>{detail.itemDetail.npwp}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{detail.itemDetail.email}</td>
                              </tr>
                              <tr>
                                <td>No HP</td>
                                <td>{detail.itemDetail.msisdn}</td>
                              </tr>
                              <tr>
                                <td>Jenis Kelamin</td>
                                <td>{detail.itemDetail.gender}</td>
                              </tr>
                              <tr>
                                <td>Tgl Lahir</td>
                                <td>{detail.itemDetail.date_of_birth}</td>
                              </tr>
                              <tr>
                                <td>Tempat Lahir</td>
                                <td>{detail.itemDetail.city_of_birth}</td>
                              </tr>
                              <tr>
                                <td>Nama Gadis Ibu Kandung</td>
                                <td>{detail.itemDetail.bio_mother_name}</td>
                              </tr>
                              <tr>
                                <td>Agama</td>
                                <td>{detail.itemDetail.religion}</td>
                              </tr>
                              <tr>
                                <td>Pendidikan</td>
                                <td>{detail.itemDetail.education}</td>
                              </tr>
                              <tr>
                                <td>Status Perkawinan</td>
                                <td>{detail.itemDetail.marital_status}</td>
                              </tr>
                              <tr>
                                <td>Jml Tanggungan</td>
                                <td>{detail.itemDetail.num_of_dependants}</td>
                              </tr>
                              <tr>
                                <td>Alamat Sesuai KTP Jalan</td>
                                <td>{detail.itemDetail.ktp_address_street}</td>
                              </tr>
                              <tr>
                                <td>Alamat Sesuai KTP Provinsi</td>
                                <td>{detail.itemDetail.ktp_prov}</td>
                              </tr>
                              <tr>
                                <td>Alamat Sesuai KTP Kota/Kab</td>
                                <td>{detail.itemDetail.ktp_kota}</td>
                              </tr>
                              <tr>
                                <td>Alamat Sesuai KTP Kecamatan</td>
                                <td>{detail.itemDetail.ktp_kec}</td>
                              </tr>
                              <tr>
                                <td>Alamat Sesuai KTP Kelurahan</td>
                                <td>{detail.itemDetail.ktp_kel}</td>
                              </tr>
                              <tr>
                                <td>RT / RW</td>
                                <td>{detail.itemDetail.ktp_address_rt_rw}</td>
                              </tr>
                              <tr>
                                <td>Kode Pos</td>
                                <td>{detail.itemDetail.ktp_address_pos}</td>
                              </tr>
                              <tr>
                                <td>Alamat Domisili Jalan</td>
                                <td>{detail.itemDetail.address_street}</td>
                              </tr>
                              <tr>
                                <td>Alamat Domisili Provinsi</td>
                                <td>{detail.itemDetail.address_prov}</td>
                              </tr>
                              <tr>
                                <td>Alamat Domisili Kota/Kab</td>
                                <td>{detail.itemDetail.address_kota}</td>
                              </tr>
                              <tr>
                                <td>Alamat Domisili Kecamatan</td>
                                <td>{detail.itemDetail.address_kec}</td>
                              </tr>
                              <tr>
                                <td>Alamat Domisili Kelurahan</td>
                                <td>{detail.itemDetail.address_kel}</td>
                              </tr>
                              <tr>
                                <td>RT / RW</td>
                                <td>{detail.itemDetail.address_rt_rw}</td>
                              </tr>
                              <tr>
                                <td>Kode Pos</td>
                                <td>{detail.itemDetail.address_pos}</td>
                              </tr>
                              <tr>
                                <td>Status Kepemilikan Tempat Tinggal</td>
                                <td>
                                  {detail.itemDetail.residence_ownership_status}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Grid>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Foto KTP</td>
                                <td>
                                  {ktpUrl ? (
                                    <>
                                      <Image
                                        src={ktpUrl}
                                        height="200"
                                        rounded
                                        onClick={() => {
                                          setVisible(true);
                                        }}
                                      />
                                      <Viewer
                                        visible={visible}
                                        onClose={() => {
                                          setVisible(false);
                                        }}
                                        images={[
                                          {
                                            src: ktpUrl,
                                            alt: ""
                                          }
                                        ]}
                                      />
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Video Selfie</td>
                                <td>
                                  {videoUrl ? (
                                    <Player
                                      playsInline
                                      src={videoUrl}
                                      fluid={false}
                                      width={400}
                                      height={200}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>Foto Selfie</td>
                                <td>
                                  {selfiePhoto ? (
                                    <>
                                      <Image
                                        src={getPhotoUrl(selfiePhoto)}
                                        height="200"
                                        rounded
                                        onClick={() => {
                                          setSelfiePhotoVisible(true);
                                        }}
                                      />
                                      <Viewer
                                        visible={selfiePhotoVisible}
                                        onClose={() => {
                                          setSelfiePhotoVisible(false);
                                        }}
                                        images={[
                                          {
                                            src: getPhotoUrl(selfiePhoto),
                                            alt: ""
                                          }
                                        ]}
                                      />
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>Foto Liveness</td>
                                <td>
                                  {livenessPhoto ? (
                                    <ol style={{ marginLeft: -25 }}>
                                      {livenessPhoto.map(function(item, i) {
                                        return (
                                          <li key={item.livenessPhotoUrl}>
                                            <a
                                              href={item.livenessPhotoUrl}
                                              target="_blank"
                                            >
                                              Liveness Photo (Gesture{" "}
                                              {item.gesture}-{item.order})
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ol>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>KYC</td>
                                <td>
                                  <Grid container spacing={2}>
                                    <GroupValueMapping
                                      label="Link KYC"
                                      value={
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          gridGap="0.5rem"
                                        >
                                          <span>{rptFinku.linkKyc}</span>
                                          <IconButton size="small">
                                            <FileCopy
                                              onClick={async () => {
                                                await navigator.clipboard.writeText(
                                                  rptFinku.linkKyc
                                                );

                                                alert(
                                                  "Link KYC berhasil disalin"
                                                );
                                              }}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Box>
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Verified"
                                      value={
                                        rptFinku.kycVerified !== undefined &&
                                        String(rptFinku.kycVerified)
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Date"
                                      value={
                                        rptFinku.kycDate &&
                                        formatDateWithTime(rptFinku.kycDate)
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Verified Date"
                                      value={
                                        rptFinku.kycVerifiedDate &&
                                        formatDateWithTime(
                                          rptFinku.kycVerifiedDate
                                        )
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Result Code"
                                      value={rptKYC.kycResultCode}
                                    />
                                    <GroupValueMapping
                                      label="Result Status"
                                      value={rptKYC.kycResultStatus}
                                    />
                                    <GroupValueMapping
                                      label="Result Message"
                                      value={rptKYC.kycResultMessage}
                                    />
                                    <GroupValueMapping
                                      label="Result Name"
                                      value={rptKYC.kycResultName}
                                    />
                                    <GroupValueMapping
                                      label="Result DOB"
                                      value={rptKYC.kycResultDob}
                                    />
                                    <GroupValueMapping
                                      label="Result POB"
                                      value={rptKYC.kycResultPob}
                                    />
                                    <GroupValueMapping
                                      label="Result Selfie Confidence"
                                      value={rptKYC.kycResultSelfieConfidence}
                                    />
                                    <GroupValueMapping
                                      label="Result Verified"
                                      value={
                                        rptKYC.kycResultVerified !==
                                          undefined &&
                                        String(rptKYC.kycResultVerified)
                                      }
                                    />
                                    <GroupValueMapping
                                      label="User Agent"
                                      value={rptKYC.userAgent}
                                    />
                                  </Grid>
                                </td>
                              </tr>

                              <tr>
                                <td>Liveness</td>
                                <td>
                                  <Grid container spacing={2}>
                                    <GroupValueMapping
                                      label="Liveness Passed"
                                      value={
                                        rptFinku.livenessPassed !== undefined &&
                                        String(rptFinku.livenessPassed)
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Liveness Date"
                                      value={
                                        rptFinku.livenessDate &&
                                        formatDateWithTime(
                                          rptFinku.livenessDate
                                        )
                                      }
                                    />
                                    <GroupValueMapping
                                      label="Liveness Passed Date"
                                      value={
                                        rptFinku.livenessPasssedDate &&
                                        formatDateWithTime(
                                          rptFinku.livenessPasssedDate
                                        )
                                      }
                                    />
                                  </Grid>
                                </td>
                              </tr>

                              <tr>
                                <td>Download Kontrak Lender - Platform</td>
                                <td>
                                  {detail &&
                                  detail.itemDetail &&
                                  detail.itemDetail.application_status ===
                                    "DISBURSED" ? (
                                    <Button
                                      size="small"
                                      onClick={e => {
                                        var signUrl =
                                          contractRes.peruri.lenderContractUrl;
                                        var win = window.open(
                                          signUrl,
                                          "_blank"
                                        );
                                        win.focus();
                                      }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Download Kontrak Lender - Borrower</td>
                                <td>
                                  {detail &&
                                  detail.itemDetail &&
                                  detail.itemDetail.application_status ===
                                    "DISBURSED" ? (
                                    <Button
                                      size="small"
                                      onClick={e => {
                                        var signUrl = "";
                                        if (
                                          contractRes.peruri
                                            .borrowerContractUrl != undefined
                                        ) {
                                          signUrl =
                                            contractRes.peruri
                                              .borrowerContractUrl;
                                        } else {
                                          signUrl =
                                            contractRes.privy
                                              .borrowerContractUrl;
                                        }
                                        var win = window.open(
                                          signUrl,
                                          "_blank"
                                        );
                                        win.focus();
                                      }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Dokumen Kredit</td>
                                <td>
                                  <ol style={{ marginLeft: -25 }}>
                                    {detail.itemDetail.document_credit.length >
                                    0
                                      ? detail.itemDetail.document_credit.map(
                                          function(item, i) {
                                            return (
                                              <li>
                                                <a
                                                  href={item.path}
                                                  target="_blank"
                                                >
                                                  {item.filename}
                                                </a>
                                              </li>
                                            );
                                          }
                                        )
                                      : ""}
                                  </ol>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Grid>
                      </Grid>
                    </TabContainer1>
                  )}
                  {value9 === 1 && (
                    <TabContainer1>
                      <Grid container spacing={10}>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Nama Perusahaan</td>
                                <td>{detail.itemDetail.company_name}</td>
                              </tr>
                              <tr>
                                <td>Industri</td>
                                <td>{detail.itemDetail.industry}</td>
                              </tr>
                              <tr>
                                <td>Tipe Pekerjaan</td>
                                <td>{detail.itemDetail.job_type}</td>
                              </tr>
                              <tr>
                                <td>Status Pekerjaan</td>
                                <td>{detail.itemDetail.job_status}</td>
                              </tr>
                              <tr>
                                <td>No NPK</td>
                                <td>{detail.itemDetail.no_npk}</td>
                              </tr>
                              <tr>
                                <td>Posisi / Jabatan</td>
                                <td>{detail.itemDetail.position}</td>
                              </tr>
                              <tr>
                                <td>Lama Bekerja ( Tahun )</td>
                                <td>{detail.itemDetail.years_of_work}</td>
                              </tr>
                              <tr>
                                <td>Lama Bekerja ( Bulan )</td>
                                <td>{detail.itemDetail.months_of_work}</td>
                              </tr>
                              <tr>
                                <td>Kode No Tlp</td>
                                <td>{detail.itemDetail.office_phone_code}</td>
                              </tr>
                              <tr>
                                <td>No Tlp</td>
                                <td>{detail.itemDetail.office_phone}</td>
                              </tr>
                              <tr>
                                <td>Alamat Kantor Jalan</td>
                                <td>{detail.itemDetail.office_street}</td>
                              </tr>
                              <tr>
                                <td>Alamat Kantor Provinsi</td>
                                <td>{detail.itemDetail.office_prov}</td>
                              </tr>
                              <tr>
                                <td>Alamat Kantor Kota/Kabupaten</td>
                                <td>{detail.itemDetail.office_city}</td>
                              </tr>
                              <tr>
                                <td>Alamat Kantor Kecamatan</td>
                                <td>{detail.itemDetail.office_district}</td>
                              </tr>
                              <tr>
                                <td>Alamat Kantor Kelurahan</td>
                                <td>{detail.itemDetail.office_villages}</td>
                              </tr>
                              <tr>
                                <td>RT / RW</td>
                                <td>{detail.itemDetail.office_rt_rw}</td>
                              </tr>
                              <tr>
                                <td>Kode Pos</td>
                                <td>{detail.itemDetail.office_pos}</td>
                              </tr>
                              <tr>
                                <td>Pendapatan Bulanan</td>
                                <td>{detail.itemDetail.monthly_income}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Grid>
                      </Grid>
                    </TabContainer1>
                  )}
                  {value9 === 2 && (
                    <TabContainer1>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          {detail.itemDetail.emergency_contact.length > 0 ? (
                            detail.itemDetail.emergency_contact.map(function(
                              item,
                              i
                            ) {
                              return (
                                <Table hover>
                                  <tbody>
                                    <tr>
                                      <td>Nama Kontak Darurat</td>
                                      <td>{item.nama}</td>
                                    </tr>
                                    <tr>
                                      <td>Hubungan Dengan Kontak Darurat</td>
                                      <td>{item.relation}</td>
                                    </tr>
                                    <tr>
                                      <td>No Hp Kontak Darurat</td>
                                      <td>{item.msisdn}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Tinggal Serumah dengan Kontak Darurat
                                      </td>
                                      <td>{item.living_status}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              );
                            })
                          ) : (
                            <Table hover>
                              <tbody>
                                <tr>
                                  <td>Nama Kontak Darurat</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>Hubungan Dengan Kontak Darurat</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>No Hp Kontak Darurat</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>Tinggal Serumah dengan Kontak Darurat</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Grid>
                      </Grid>
                    </TabContainer1>
                  )}
                  {value9 === 3 && (
                    <TabContainer1>
                      <Grid container spacing={10}>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Nama Bank</td>
                                <td>{detail.itemDetail.bank_code}</td>
                              </tr>
                              <tr>
                                <td>No Rekening Pencairan ( VA )</td>
                                <td>{detail.itemDetail.rekening_pencairan}</td>
                              </tr>
                              <tr>
                                <td>Nama di Rekening</td>
                                <td>{detail.itemDetail.account_name}</td>
                              </tr>
                              <tr>
                                <td>No Rekening Pinjaman</td>
                                <td>{detail.itemDetail.rekening_pinjaman}</td>
                              </tr>
                              <tr>
                                <td>No Rekening Simpanan</td>
                                <td>{detail.itemDetail.rekening_simpanan}</td>
                              </tr>
                              <tr>
                                <td>Nama Bank Pendamping</td>
                                <td>{detail.itemDetail.companion_bank}</td>
                              </tr>
                              <tr>
                                <td>No Rekening Pendamping</td>
                                <td>
                                  {detail.itemDetail.companion_account_no}
                                </td>
                              </tr>
                              <tr>
                                <td>No di Rekening Pendamping</td>
                                <td>
                                  {detail.itemDetail.companion_account_name}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Grid>
                      </Grid>
                    </TabContainer1>
                  )}
                  {value9 === 4 && (
                    <TabContainer1>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Nama</td>
                                <td>{detail.itemDetail.full_name}</td>
                              </tr>
                              <tr>
                                <td>No HP</td>
                                <td>{detail.itemDetail.msisdn}</td>
                              </tr>
                              <tr>
                                <td>Tgl Lahir</td>
                                <td>{detail.itemDetail.date_of_birth}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{detail.itemDetail.email}</td>
                              </tr>
                              <tr>
                                <td>Loan ID</td>
                                <td>{detail.itemDetail.fineract_loan_id}</td>
                              </tr>
                              <tr>
                                <td>VA BNI</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>VA BCA</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>VA Mandiri</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>Indomaret</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>Alfamart</td>
                                <td>-</td>
                              </tr>
                              {detail &&
                                detail.itemDetail &&
                                detail.itemDetail.application_status ===
                                  "DISBURSED" && (
                                  <tr>
                                    <td>Link Pembayaran</td>
                                    <td>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gridGap="0.5rem"
                                      >
                                        <span>{paymentLink}</span>
                                        <IconButton size="small">
                                          <FileCopy
                                            onClick={async () => {
                                              await navigator.clipboard.writeText(
                                                paymentLink
                                              );

                                              alert(
                                                "Link pembayaran berhasil disalin"
                                              );
                                            }}
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Box>
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </Table>
                        </Grid>
                        <Grid item xs={6}>
                          <Table hover>
                            <tbody>
                              <tr>
                                <td>Produk</td>
                                <td>{detail.itemDetail.product}</td>
                              </tr>
                              <tr>
                                <td>Pokok Pinjaman</td>
                                <td>
                                  {toIndonesiaCurrency(
                                    detail.itemDetail.principal_loan
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Biaya Layanan</td>
                                <td>
                                  {toIndonesiaCurrency(
                                    detail.itemDetail.service_fee
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Bunga</td>
                                <td>{detail.itemDetail.interest}</td>
                              </tr>
                              <tr>
                                <td>Jangka Waktu</td>
                                <td>{detail.itemDetail.tenor}</td>
                              </tr>
                              <tr>
                                <td>Total Pencairan</td>
                                <td>
                                  {toIndonesiaCurrency(
                                    detail.itemDetail.total_disburse
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Nilai Angsuran</td>
                                <td>
                                  {toIndonesiaCurrency(
                                    detail.itemDetail.installments
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Jatuh Tempo</td>
                                <td>{detail.itemDetail.jatuh_tempo}</td>
                              </tr>
                              <tr>
                                <td>Status Pinjaman</td>
                                <td>{detail.itemDetail.status_pinjaman}</td>
                              </tr>
                              <tr>
                                <td>Waktu Pengajuan</td>
                                <td>{detail.itemDetail.application_date}</td>
                              </tr>
                              <tr>
                                <td>Waktu Pencairan</td>
                                <td>
                                  {detail.itemDetail.application_status ===
                                  "DISBURSED"
                                    ? detail.itemDetail.updated_date
                                    : ""}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Grid>
                        {detailPayment.length > 0 ? (
                          <Grid item xs={12}>
                            <Table responsive="sm" hover>
                              <thead>
                                <tr>
                                  <th>Angsuran</th>
                                  <th>Nilai Angsuran</th>
                                  <th>Tgl Jatuh Tempo</th>
                                  <th>Nilai Pembayaran</th>
                                  <th>Tgl Pembayaran</th>
                                  <th>Status Pembayaran</th>
                                  <th>Channel</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detailPayment.map(function(item, i) {
                                  return (
                                    <tr key={i}>
                                      <td>{item.angsuranKe}</td>
                                      <td>
                                        {toIndonesiaCurrency(
                                          item.totalAngsuran
                                        )}
                                      </td>
                                      <td>{item.tglJatuhTempo}</td>
                                      <td>
                                        {toIndonesiaCurrency(
                                          item.totalAngsuranDibayar
                                        )}
                                      </td>
                                      <td>{item.tglRepayment}</td>
                                      <td>{item.statusRepayment}</td>
                                      <td>{item.channel}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Grid>
                        ) : (
                          ""
                        )}

                        <Grid item xs={12} md={6}>
                          <InfoHapusBukuView
                            loanId={detail.itemDetail.fineract_loan_id}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <RecoveryPaymentTable
                            loanId={detail.itemDetail.fineract_loan_id}
                          />
                        </Grid>
                      </Grid>
                    </TabContainer1>
                  )}
                </div>
              </div>
            </CodeExample>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
